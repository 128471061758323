import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Node-RED_Tutorials/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Node-RED and IFTTT",
  "path": "/Advanced_User/Node-RED_and_IFTTT/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "If This Then That, also known as IFTTT is a free web-based service to create chains of simple conditional statements, called applets. Build your own applets to connect web services with your INSTAR IP Camera. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_IFTTT.png",
  "social": "/images/Search/AU_SearchThumb_IFTTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_NodeRED_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Node-RED and IFTTT' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='If This Then That, also known as IFTTT is a free web-based service to create chains of simple conditional statements, called applets. Build your own applets to connect web services with your INSTAR IP Camera. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_IFTTT.png' twitter='/images/Search/AU_SearchThumb_IFTTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Node-RED_und_IFTTT/' locationFR='/fr/Advanced_User/Node-RED_and_IFTTT/' crumbLabel="Node-RED" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "node-red-and-ifttt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#node-red-and-ifttt",
        "aria-label": "node red and ifttt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED and IFTTT`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#basic-setup"
        }}>{`Basic Setup`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#node-red-introduction"
            }}>{`Node-RED Introduction`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ifttt-setting-up-a-webhook"
        }}>{`IFTTT Setting up a Webhook`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#node-red-contacting-the-external-webhook"
            }}>{`Node-RED Contacting the external Webhook`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ifttt-contacting-a-node-red-endpoint"
            }}>{`IFTTT Contacting a Node-RED Endpoint`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "basic-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basic-setup",
        "aria-label": "basic setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basic Setup`}</h2>
    <p><em parentName="p">{`If This Then That`}</em>{`, also known as `}<a parentName="p" {...{
        "href": "https://ifttt.com/"
      }}>{`IFTTT`}</a>{` is a free web-based service to create chains of simple conditional statements, called applets. Build your own applets to connect web services with your INSTAR IP Camera.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "node-red-introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#node-red-introduction",
        "aria-label": "node red introduction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Introduction`}</h3>
    <p>{`Use your Node-RED Installation under `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/"
      }}>{`Windows`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Node-RED/"
      }}>{`Linux`}</a>{` (e.g. on a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Node-RED_Setup/"
      }}>{`Raspberry Pi`}</a>{`) to serve as an interface between your camera and the `}<a parentName="p" {...{
        "href": "https://ifttt.com/discover"
      }}>{`IFTTT services`}</a>{`. Node-RED will serve both to send messages to the IFTTT Webhook API when an event occurs on your local network, as well as provides an HTTP Endpoint for your IFTTT Applets to trigger events on your local network.`}</p>
    <p>{`An example would be a camera triggers an alarm, contacts Node-RED that forwards a message to IFTTT, that in turns sends a message to your smartphone. Or the other way around - your phones GPS detects that you are arriving home, the IFTTT smartphone app contacts the IFTTT server that your local Node-RED installation should be informed to deactivate the motion detection on your INSTAR IP cameras.`}</p>
    <h2 {...{
      "id": "ifttt-setting-up-a-webhook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ifttt-setting-up-a-webhook",
        "aria-label": "ifttt setting up a webhook permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IFTTT Setting up a Webhook`}</h2>
    <ol>
      <li parentName="ol">{`First you need to `}<a parentName="li" {...{
          "href": "https://ifttt.com/join"
        }}>{`create an account`}</a>{` on IFTTT and `}<a parentName="li" {...{
          "href": "https://ifttt.com/login?wp_=1"
        }}>{`login to your account`}</a>{`. Then go to the `}<strong parentName="li">{`My Applets`}</strong>{` tab and click on `}<strong parentName="li">{`New Applet`}</strong>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/371616c467543d217f4c74673467cdbb/e9beb/IFTTT_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABgUlEQVQoz42OS0sCURTH53O1aVOLIIiQ6HukpllRa8HNKITj1DIIZ2HtLMRFIqg0FZHQwyIdJ3Ue9955Sc3MPTEjCJaLfhwO//P4cw6zsLi8FtlI7R8kUvux7Z1oLBGNBxGLJ7di2zMRneR4MrW7HtlcXVliri4vq9UqRkgZjXRdwxghXScYIaSPnV8EDcs0NU176bzdNGuM2EU3H3p7OL7rkTuJ3Paw2EX3EhF7+FUdm6Y1xSDEMgxCyKcsq6qmDbqMR6kP4PrgAwxVDRsmDUuPwte3a1m2Zdm2bY8dR1YUhZBv16WUAgBSJIaGQACFQEzyDJMN5/TM3Tt8uK4dFTi+cHzbqjEwD1XVJKkvh/T7fdMwAMDrSfSxjVX16fm503kfSB3m7xEAKJVKLMtyHMfzfDabbTQa09FUWHg031ypVARBOL+4KBaLPF9otZrByPPA9ymlnueF5uF8syAImUwml8uxLJtOp+v1OgD4vv+vy+VyucBx+Xz+JEQUxblv/wAlvgUBhahqpQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/371616c467543d217f4c74673467cdbb/e4706/IFTTT_01.avif 230w", "/en/static/371616c467543d217f4c74673467cdbb/d1af7/IFTTT_01.avif 460w", "/en/static/371616c467543d217f4c74673467cdbb/b6582/IFTTT_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/371616c467543d217f4c74673467cdbb/a0b58/IFTTT_01.webp 230w", "/en/static/371616c467543d217f4c74673467cdbb/bc10c/IFTTT_01.webp 460w", "/en/static/371616c467543d217f4c74673467cdbb/87ca7/IFTTT_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/371616c467543d217f4c74673467cdbb/81c8e/IFTTT_01.png 230w", "/en/static/371616c467543d217f4c74673467cdbb/08a84/IFTTT_01.png 460w", "/en/static/371616c467543d217f4c74673467cdbb/e9beb/IFTTT_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/371616c467543d217f4c74673467cdbb/e9beb/IFTTT_01.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click on `}<strong parentName="li">{`this`}</strong>{` to create the conditional part of the script - we want to select what needs to happen for this script to be executed:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2602519c01f2eed7db792ac1768ef353/e9beb/IFTTT_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABjklEQVQY001OSy8DURS+Qz12QiRIiIRYIBZiYUEs/Qw/wc+wJIR2OkW1qtV6RG0r0WqoV7SCaZAuPFqddsQ7Wr1zPrkt4uTLyTkn53uw3jUMbaJuAY1OdCyjwSnmNjdq5tHsQq0dzApJgWQFk1Fr512er1Y3WpZQZQOrmkX1LEwKyhVU2EQ3yVyyELNAwFyELPhMgcmGShvKlB8wqSj8t5dZwebQ76e+VXT5MOjHwAbaPcK//P9biSzkZTAzCVhEvM6x6x7Ha7sPTU7qXaVuH+oXhL9UyiLjJ5QFbF/DfhqHWRxl6PgJhxcZrbXzaHwx8ILQPd9O0dadEUoakTQdZBBJG7spI5KGYGlgIAKQe3vOvT1reXy+fjw6vFxVAYB4IfeO3yrkP3nhqzhS6cLy3ACw4vUo64HhAEaCxmgMM9dkv0JwJzwxOeX2+lbW1qOnZ9Nm2bHocrmXw3sRAHluMCo6J+9uk9kn/w3CKTp54Oe6cawjm9WvLi9PotF4PK7reiKRUFU1Fotpmibcib4B10RVau76iw8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2602519c01f2eed7db792ac1768ef353/e4706/IFTTT_02.avif 230w", "/en/static/2602519c01f2eed7db792ac1768ef353/d1af7/IFTTT_02.avif 460w", "/en/static/2602519c01f2eed7db792ac1768ef353/b6582/IFTTT_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2602519c01f2eed7db792ac1768ef353/a0b58/IFTTT_02.webp 230w", "/en/static/2602519c01f2eed7db792ac1768ef353/bc10c/IFTTT_02.webp 460w", "/en/static/2602519c01f2eed7db792ac1768ef353/87ca7/IFTTT_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2602519c01f2eed7db792ac1768ef353/81c8e/IFTTT_02.png 230w", "/en/static/2602519c01f2eed7db792ac1768ef353/08a84/IFTTT_02.png 460w", "/en/static/2602519c01f2eed7db792ac1768ef353/e9beb/IFTTT_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2602519c01f2eed7db792ac1768ef353/e9beb/IFTTT_02.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`As mentioned above, we want to use the `}<a parentName="li" {...{
          "href": "https://ifttt.com/services/maker_webhooks"
        }}>{`webhook service`}</a>{` - which is basically just a web address that your Node-RED installation needs to contact to trigger the IFTTT applet. Search for `}<em parentName="li">{`web`}</em>{` and select the `}<strong parentName="li">{`Webhooks Service`}</strong>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8ae6dbe470aef55485cd68c1cecbe989/e9beb/IFTTT_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABxUlEQVQoz4WSO28TQRSFVxRY4VGkoECitUQZESSIgpMiTZpIIIUi/4A/QUWNRBMUTIwCDSBZTjaYxDFSCqAgBSC5AQkTwXpnZh8QJ17vws7O3IN2qLAtcnW6e797dB9W8SkuPqdCGacqOPcEp1cxtorxNZx4gDOPUHgIaxnWfaNlFFbo/GM5vpYXjJWltdDA9SambZRszNVxzcb0Bs3YemqdSjZdrdGlqp6s0uUaJmuY2qD5up7dxMwmSuvSStKMeWEqMw1kBG00HGSkAamhCAqQUlog0pkEkcnnnJTp/te24DwI/DDwmesKLpRSA+1UJq1hE62VL5hwHcE6vmCB4KF3HMwOfjOvx8QR//nrR4YgBY9JJBSkCCV4nIvF8JIBmEgDzbft1u67ve3X7Y9fmASLtJ+QlxDvkxsRi+HGEAn2fOTrGXDudOU3ETlh4hwpp0efungf4EOIz110IjhG33vg/ZEzm21R3FeMA7jZpIkqis9w602e/Os2amaD6cPe4cLiwcSV8EIRW1uzO1h6pedf4sbOPzDRKGdK07TeSFYq0e078PjcC5yt0MkyFhv/dx74B9O7to97LdxtYbtj7jf0OUrJP7NleDS0CiEcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ae6dbe470aef55485cd68c1cecbe989/e4706/IFTTT_03.avif 230w", "/en/static/8ae6dbe470aef55485cd68c1cecbe989/d1af7/IFTTT_03.avif 460w", "/en/static/8ae6dbe470aef55485cd68c1cecbe989/b6582/IFTTT_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8ae6dbe470aef55485cd68c1cecbe989/a0b58/IFTTT_03.webp 230w", "/en/static/8ae6dbe470aef55485cd68c1cecbe989/bc10c/IFTTT_03.webp 460w", "/en/static/8ae6dbe470aef55485cd68c1cecbe989/87ca7/IFTTT_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ae6dbe470aef55485cd68c1cecbe989/81c8e/IFTTT_03.png 230w", "/en/static/8ae6dbe470aef55485cd68c1cecbe989/08a84/IFTTT_03.png 460w", "/en/static/8ae6dbe470aef55485cd68c1cecbe989/e9beb/IFTTT_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8ae6dbe470aef55485cd68c1cecbe989/e9beb/IFTTT_03.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b46ce09c4ca30c91c21bc8fb6ce9c7d6/e9beb/IFTTT_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACBUlEQVQoz4VSTWhTQRDeKBiMWMVAqUhFUVAUVDzYKoqRoogiLfRQ9KAFbeoP1YNeahGKIJ61oDZV0qqVYohpjRdFW/PSKg2KJ5vG1+aiBExf9FXyGl52dz7ZRMXCQ4dhmJ2Zb2f3m2EbHmFrGEsCcN9F5X147mHBbSwPwt2tIot7wG6CdcHVpZxlAVHVV1zRi4ogXLfA2kZxfpRaNbRqOBuHX8OFMVx8i9Ma/Bq1xOhUjE6+LtkYzsRxLk5+lUJLDAwkC9+zsmgDIChJmfhggMqHfwrjElwSlyhKFIQK1Uepuh9vJr8kJyaS+nRKnxp/9z6Z0m1OglCq/6Xs75vKzV58RmgaZn7OmMkauW+WZWUyGdM0HTr/8bhEnisVpHROKPufZ5dhAC4nsC2MvU+xaxC+KLaE0DYGQAhJJARxDikhyRl87KWaTfVDNbDV/XD34NAzVWpP6lbnNdi24lBKZ3DzCKoeoCaCTY+xPYzKPmocBlIfjVqfsWrdbEOTWXc4f6n9Nz00D3x8WDWsjWBzCDVPqKIX9YMFqtv/1ePNrdk44/Fm2SLTd8AZ3DyC9QPYM4QdEewegjdIDa+A9Cdj577cyrU/jp6YPdJodXQ6g5uew9WNpUGwO1gYALuBg9HSn/W0dfU6OHdmu0xhOK228koC7ePoSCh/YEolJRFJCSFUt/l79xOg4YR5Zkq/jQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b46ce09c4ca30c91c21bc8fb6ce9c7d6/e4706/IFTTT_04.avif 230w", "/en/static/b46ce09c4ca30c91c21bc8fb6ce9c7d6/d1af7/IFTTT_04.avif 460w", "/en/static/b46ce09c4ca30c91c21bc8fb6ce9c7d6/b6582/IFTTT_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b46ce09c4ca30c91c21bc8fb6ce9c7d6/a0b58/IFTTT_04.webp 230w", "/en/static/b46ce09c4ca30c91c21bc8fb6ce9c7d6/bc10c/IFTTT_04.webp 460w", "/en/static/b46ce09c4ca30c91c21bc8fb6ce9c7d6/87ca7/IFTTT_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b46ce09c4ca30c91c21bc8fb6ce9c7d6/81c8e/IFTTT_04.png 230w", "/en/static/b46ce09c4ca30c91c21bc8fb6ce9c7d6/08a84/IFTTT_04.png 460w", "/en/static/b46ce09c4ca30c91c21bc8fb6ce9c7d6/e9beb/IFTTT_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b46ce09c4ca30c91c21bc8fb6ce9c7d6/e9beb/IFTTT_04.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Then name the event - this will be part of the URL that we will later have to contact with Node-RED to trigger the event:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a2562032c4b5eb44daf9ce2000994733/e9beb/IFTTT_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACcElEQVQ4y43T7UtTURwH8ItKYUSFLh8qZro0hUjMNwXSHxAEImiCUYSB9MI/oD/Ad1EoWTqXbTN3p7nVC2u6B6eWEWXWUuuF7W5zNdjK63bd3d3TPd+4uyvJmnT4cl4czuf8fofDoSKRSDAYTCSTyAw+BTaONMH/DCqVSgmCIIqivP+qExUGmL8k01F2i4+FN8PhcJjneY7j4nEhGt3a2GBDoZAgCBKWzyAAyehWK6k0QOvi3atL9tl5m90xN/9yeWXF5pgxmc0fXB9fv1202W0M49nGsgfgjmCZRSL9e4VkIv6a/2x7B04TvP8ByzosPjLlI8+84qQnPekVrX4yG4DjK7H5RVYQ5Ua3sXxphoPSgBojlKM49gjVRqhonBzDYS2ou8i7B6oPU+vZMjuxh0PFqASOG7JR0dIRlQYUaVGsAzWA57mwd0uqqaJRN4aqjFTRkqwxoppG7TgKNbmxh5N2nxrHuadoMKH+Mc4+QaMZZ0xSlKOg+jHlz41P0GicILVGUqYj5SOkVI+yEZSPoESPAvWu2MfhqAGlNJRGHNCBUoMa3E4W52ibMFHU6WJN/etNmmDTQKCqL7R3GPseoDCT/cPIV0sP+W/s5lGv2Wy77Woe9HSo1873MkV6lOiJQg+FDsVa7BnKiaW2S8egmICCRsmweEhL8jUo0CBvSCp78OFudyZMDBd6lm5cUV+7OX39Bbrs0U4r32nlu+cSpydA3ZcynQOnVxPo6bj16kjD3MUu1zfuzWfvuzW/ffETE/jevQDqDqF6YfH9heVfxcZxeQHtFqHDGmtxon2GtDvR5kCrAy1WXLKheVr6OXKxn9oePiCuLnHVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2562032c4b5eb44daf9ce2000994733/e4706/IFTTT_05.avif 230w", "/en/static/a2562032c4b5eb44daf9ce2000994733/d1af7/IFTTT_05.avif 460w", "/en/static/a2562032c4b5eb44daf9ce2000994733/b6582/IFTTT_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a2562032c4b5eb44daf9ce2000994733/a0b58/IFTTT_05.webp 230w", "/en/static/a2562032c4b5eb44daf9ce2000994733/bc10c/IFTTT_05.webp 460w", "/en/static/a2562032c4b5eb44daf9ce2000994733/87ca7/IFTTT_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2562032c4b5eb44daf9ce2000994733/81c8e/IFTTT_05.png 230w", "/en/static/a2562032c4b5eb44daf9ce2000994733/08a84/IFTTT_05.png 460w", "/en/static/a2562032c4b5eb44daf9ce2000994733/e9beb/IFTTT_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a2562032c4b5eb44daf9ce2000994733/e9beb/IFTTT_05.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`With the trigger set up we now need to assign an IFTTT service we want to trigger. To do this click on `}<strong parentName="li">{`that`}</strong>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/474b477ab79ce8addc8dfee107938eb5/e9beb/IFTTT_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.21739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABQklEQVQY06VNy0oCURg+LSqsIJJ6gDbteoDCwECFFi1a9Qa9Qos27dsEWakl2QVKiESb8RKF0IWJIEpQtBuBt4kQi7TUc+acL2ZG6AH6+fj4+fguZHgPIwfo9sHix+A2LJvo2sBAAB1e9PrR6QNxg6wKYjx9Xj4UYNYA+rdA1kBmEpg9hUPSMRXF5LEOl6yzQ4I9AlsEtiM67lHHQtQpYzrKXTKcEibCIE2qqe+VZosCEIDJOgTXOOeMAWCSXO2x0liCA5rGuQA3rAQQjDHOOW385KpaPI9KQ9eEaFfVBD5ey3X/Di0XDcHUjbBpKqlv68tLC0prNATPVWHXuxI+lveDweuL5Nwl7FExn4X7Ae404gU91l42w7X693Muc1LE4i1u8l+P6dTdfUpRlKds5kzF4Ys4L2rJEmIF5D7/5gn+cb/H21qBw8MUEwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/474b477ab79ce8addc8dfee107938eb5/e4706/IFTTT_06.avif 230w", "/en/static/474b477ab79ce8addc8dfee107938eb5/d1af7/IFTTT_06.avif 460w", "/en/static/474b477ab79ce8addc8dfee107938eb5/b6582/IFTTT_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/474b477ab79ce8addc8dfee107938eb5/a0b58/IFTTT_06.webp 230w", "/en/static/474b477ab79ce8addc8dfee107938eb5/bc10c/IFTTT_06.webp 460w", "/en/static/474b477ab79ce8addc8dfee107938eb5/87ca7/IFTTT_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/474b477ab79ce8addc8dfee107938eb5/81c8e/IFTTT_06.png 230w", "/en/static/474b477ab79ce8addc8dfee107938eb5/08a84/IFTTT_06.png 460w", "/en/static/474b477ab79ce8addc8dfee107938eb5/e9beb/IFTTT_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/474b477ab79ce8addc8dfee107938eb5/e9beb/IFTTT_06.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Let's use the `}<strong parentName="li">{`Email service`}</strong>{` - when the URL (webhook) for this applet is contacted we want to receive an Email to the address that we used to register with IFTTT:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6f057db4e0642eec5138ea4897f66455/e9beb/IFTTT_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACN0lEQVQoz5WNXUhTcQDFb/hQGlSGVAR9QZMgMyz6einISChCiPIhMSudplMLlj2ERSEJxQgi03SKZhQ9CBkRfVht11QSISjN3W3/LYVGtvnfvXcfut27///EhkqvHX4czsPhHGHbM+zsxfJ2pHdiTQ8yOpHWhqzHWNqGZR1It0J4AKEZS5qTIdOaWNetr+7Gii4ILRBMg6gd5EYRRhEXPyf9gp0bRV6eoszOz9kWsKNygFcNzJfP2yGAsznZz7Q4/l+CzqAxniTBGRCZjX0bG3e6PeTn1ITT6XC5iXdybMJBJqe0BE9waAz6AsLiDE95gjFFVRVFUWXZH5iZoUE1FFJUNRyOMD7f+eeZg3FEdfygmkRjJMQ8IXiiIHEQBZ4QvGF4Q8lAFO6UOVE4W5CQGuOqjq+/Yr5A9I8KXxzT3yfok+cUCMZ5UOM0zmkcsg6qwaGCLT6PBjXRr41QXQy4305/ef976JM8O2x54Txwot8Dm8Q/SrC5ILpjdlf0gzQ74qYOJxlzEMk9KazsmykbjWx8LW/uL2hyVddIxza1NF6+EX5qGTaYcLKJl9zjWyuxr56YHkl3XwZyz7QXFldVmG/vOlIsrH9Frd65zL7g2jf59z0NJqkwy3Ktvgs9I4mcOmRX41IHDjdgex3psfkqrT7D6dZTpTXX77TuOFQkpPUGVvXRtF664V3+bjErZyjD8PBWdjlya7UtFdhzBUdvYv9V5JlJkWU8v5Hkne04eLy4sNS8t6DkL9sz+dpoAgIPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f057db4e0642eec5138ea4897f66455/e4706/IFTTT_07.avif 230w", "/en/static/6f057db4e0642eec5138ea4897f66455/d1af7/IFTTT_07.avif 460w", "/en/static/6f057db4e0642eec5138ea4897f66455/b6582/IFTTT_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6f057db4e0642eec5138ea4897f66455/a0b58/IFTTT_07.webp 230w", "/en/static/6f057db4e0642eec5138ea4897f66455/bc10c/IFTTT_07.webp 460w", "/en/static/6f057db4e0642eec5138ea4897f66455/87ca7/IFTTT_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f057db4e0642eec5138ea4897f66455/81c8e/IFTTT_07.png 230w", "/en/static/6f057db4e0642eec5138ea4897f66455/08a84/IFTTT_07.png 460w", "/en/static/6f057db4e0642eec5138ea4897f66455/e9beb/IFTTT_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6f057db4e0642eec5138ea4897f66455/e9beb/IFTTT_07.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ae250178d9fcf89c40a5b85b8267db72/e9beb/IFTTT_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABy0lEQVQoz5WRX0iTURjGvyBidWMs6MayIDTqqpBqBmpqENIf+kcXRVDIqEYQW7VoSBFhEHihKDUXxsQQIWq5KWHUTVcRQTcRwbzQ77PSrzVMSvbnO+8vzlxCgwU9vByei+d3zsN7jOohaobF1cfKftYM6HN5BHeUFWHtXQ8wejB6WdarTUXEWRvNu6O4+lk3oIzD4xx7yZ4ETQn2jWnTPMqBFzTEpTEuDSPiiYnnqdTFZFeMloS0jqmWUerjHB1XxkLO+WynMtmcgrwC+DbPxFecgi+RIzrjiB6llIFIPpcV0VlVAO4+4XQXbz+kZr9MTk2Z9uzMRDJp2zYgIksXFeA/Eim+9uwNnSMkzR/T1qRpmqlUyrKsdDpd0uIv+H9VhFWhS/QVgYfcGCI0SOgRF/sYfi06I/+EF9ue6WbzBXZeZbtfz6oT+MKaW4g/z3/8JCDZbFnYF6Y+xKEOWm9x5I7sCBLo+cUVv726Mr1l2/dNW+fbzrO01RL43D1qfNQF8QTZfU2qvPjvZ+jqtCur5/bunzt4/OfN22Xhs91UnGSjl/VtbPDiPsWliK6defdeWdPF/yhX+/ogtQGa22kM0dRO7WU6Hi9myi7sN5NUVBWwco+fAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae250178d9fcf89c40a5b85b8267db72/e4706/IFTTT_08.avif 230w", "/en/static/ae250178d9fcf89c40a5b85b8267db72/d1af7/IFTTT_08.avif 460w", "/en/static/ae250178d9fcf89c40a5b85b8267db72/b6582/IFTTT_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ae250178d9fcf89c40a5b85b8267db72/a0b58/IFTTT_08.webp 230w", "/en/static/ae250178d9fcf89c40a5b85b8267db72/bc10c/IFTTT_08.webp 460w", "/en/static/ae250178d9fcf89c40a5b85b8267db72/87ca7/IFTTT_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae250178d9fcf89c40a5b85b8267db72/81c8e/IFTTT_08.png 230w", "/en/static/ae250178d9fcf89c40a5b85b8267db72/08a84/IFTTT_08.png 460w", "/en/static/ae250178d9fcf89c40a5b85b8267db72/e9beb/IFTTT_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ae250178d9fcf89c40a5b85b8267db72/e9beb/IFTTT_08.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Now we can configure the subject and body text the email should have. Notice the words that are highlighted in white - these are variables that will be filled with data before the mail is sent. `}<strong parentName="li">{`EventName`}</strong>{` will be the name that you choose in step 4 above, `}<strong parentName="li">{`OccuredAt`}</strong>{` will be filled with a time stamp. The `}<strong parentName="li">{`Value 1-3`}</strong>{` below are variables that we can define in Node-RED and send them whenever the event is triggered in our flow - the naming, as well as the amount of variables that you want to add is up to you:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/473775971701cae7801f64c0149ee9d9/e9beb/IFTTT_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "113.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAIAAACEf/j0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADYElEQVQ4y42Ua0yTVxjH+bIP7oNLzLJkOjUqUBfnDVGckOC2WOdcDV5inNcJDeUtFJxdsQWaMqgwFiYYlXZTg454gWSITCSiiJGJIlnHUvZh0bG5IOyiwtq37+2c9/yXg2QzW16yJ/88OcnJ7zzn+Z9LXDQaHR4ekWUFAAMPRQOh+D8Rp2laLCZSSvVxtO8edh+GpwGjItV1QghRFR6UUlVVNU1TVEWSJEVRGGNxfy9DdZ7bQ9hSxYQgQgMP+u7evnmzu6urq6enJxwOd3d39/beCYVC1zo7OzquPBkd+wdm45UfRdD0NW4MgFIGRglR5VhUVSSiKYDOGBlvjk/o+jOVnw2qIyIhKnONifpolIoyiymYkIqnPcb9iwFQeBopLphLsboE6cV404s1Piz/cEIrXEh24ochA9hWh1Q3NhzE2x/B7MN6P+fnZCMhB/EC4nMwIxPhnw1gIcgrmH1YU4q1PrxThre8WF+OjR9jXRkW5mOWFQMPDOCcAF7LR5qbZ5OAV/NgsvOaiXbMtXFy9iSwEESqB5aDWOZE0n6uZCdWe7R0t/JGkZxepCcKCBvBuZ8jqYBmeKUtlXRjJdtUBetRHG15HLjw8ETrkLdBNdnx/S/MEF5aoO+qlC0eMc0ReX2fmuZmSz7A/DwsKECCHQnCpD2b7FjpxpICtthBlzqIyc4yKnT/uZi3IVbRKFn8LPSjAZxdh2k7MDebn0p8Dq8z24oMP6k+/5uvfrjq7IilnBjCQhCrDvB7MjOL6xUrZlq5yS9n8vGMLEzfa7xtexBpHn6keQGppP6R6/hj10lpbSkW5WOBA0n72cJ840uS+xmSnWxDBaq/lHq/vdffH+7rHyxqgLlETi0kiQJfYjLD3i2ntc1PKhpF2zGt+MSQ99TYgXpSdPxXR50YL2B+rjFsCyDVzbJqxjJrxJRCmPJgysVyJ1tXSsxeYimTU1zsu5+MH8aLuzHHhhe246U93K1pO7GtmjR1jjRefdhxZ/j9WtJ338jtADd5ng1ljWi5FT3TGTl/Qzx3PdLSq6cXTzwsw22/9yme24zntzJ/E9pv/37y4v2zV4a+aBtsu6ss2ocpW9nUbegf/A/89H9o/waHLqLuMj5pxqFWHG5DzVc8VzWjthXHLuPIJfzxJ4f/Ai4/SoCIHRnoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/473775971701cae7801f64c0149ee9d9/e4706/IFTTT_09.avif 230w", "/en/static/473775971701cae7801f64c0149ee9d9/d1af7/IFTTT_09.avif 460w", "/en/static/473775971701cae7801f64c0149ee9d9/b6582/IFTTT_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/473775971701cae7801f64c0149ee9d9/a0b58/IFTTT_09.webp 230w", "/en/static/473775971701cae7801f64c0149ee9d9/bc10c/IFTTT_09.webp 460w", "/en/static/473775971701cae7801f64c0149ee9d9/87ca7/IFTTT_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/473775971701cae7801f64c0149ee9d9/81c8e/IFTTT_09.png 230w", "/en/static/473775971701cae7801f64c0149ee9d9/08a84/IFTTT_09.png 460w", "/en/static/473775971701cae7801f64c0149ee9d9/e9beb/IFTTT_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/473775971701cae7801f64c0149ee9d9/e9beb/IFTTT_09.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Confirm your setup and save your applet:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b9ad12fb734c03d6594efa5ef650d7ba/e9beb/IFTTT_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "114.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAIAAACEf/j0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC5UlEQVQ4y42UeUgUcRTH3zoblXSIGW10YBQh0kFBB9U/ERVFRUREd3n0V4VBubUatmlgaYGa2rraZqvR5RUea9Z6bZbHXm1ugWnpmltsW1mQYjq/b/zMNCJ3+/HhMcy8z8x7b34z9N7pbO/o+Pr1m8vlcjgc3d3dDOgdQJ/I419874fIMLyIMSaKImP83MDghXgLgu5iZSGWFWBp/gjLC7HoPtLsGM4k/LH6RR7360FJOFYHWRYoBZJ0UBooFX7XMf0mjj8ZyfyXXIktOqheIqIOkgwI6iHGZPJbnG7wKB+oxOI87KjAxjKQapBrHEENugp5vUc5rBoBWmwtxyYdVj/A2mKsL8WaIl6Fd/lwFWQ5vPh1JdhQiu0PsVvPh8dL8CofrML8O9inx6oi7NFj12Ns1mFdMVYU8PnJPfd8qArB97BXj23lmKxhfjcwSQP/bEzJ/r+eScVm5CDgJiid0bUhBBXzLofXgJKYkP7bVPFR+WRAqv4PObQaPhks6PaPaVrItGyiBhI1fNRMUPdTKvNWdg0ojQXmDkzVQpaLCRpQBqSZGJ8JSvY2sL16/mIbP6Kmi+lavhmcA00uVHS9j3+lXJjfFVEHgI0q73zEkm1844ti/5cOe19PN4DO3qcqJ4UYDEcN/LsYfZNUY5wG4QbOkTqE1SK0lh/vqvwsZIknR++ZPzC8xEXRLYKylWJa6VwrKdvofBsp24TzDop+Lde7hzP/IYflvyF5/fxEa+BFy7QLpimxxoA4kzSqQVA8oxNP5WUOj3JBB50xzkp4IYu3+cVZx8WY515uXpL6KjjZTgpjpO6dJzk0v10SbVqQYp95yRZwwTI2xjQ7wTbvSvOcRBudaYrUdXqSwwvbKbLR95xZEmUkhfFXJIVRetZEpxpOl3uUQ/Le0rFngryJIhs5pxql0SZfpXl6/HP/WMuJ4lF6HvwLwtz1/YbJfev5p1zrEDmWT1qLO9fqzja7mz/0DGf+BPCuQUSSzTy8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9ad12fb734c03d6594efa5ef650d7ba/e4706/IFTTT_10.avif 230w", "/en/static/b9ad12fb734c03d6594efa5ef650d7ba/d1af7/IFTTT_10.avif 460w", "/en/static/b9ad12fb734c03d6594efa5ef650d7ba/b6582/IFTTT_10.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b9ad12fb734c03d6594efa5ef650d7ba/a0b58/IFTTT_10.webp 230w", "/en/static/b9ad12fb734c03d6594efa5ef650d7ba/bc10c/IFTTT_10.webp 460w", "/en/static/b9ad12fb734c03d6594efa5ef650d7ba/87ca7/IFTTT_10.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9ad12fb734c03d6594efa5ef650d7ba/81c8e/IFTTT_10.png 230w", "/en/static/b9ad12fb734c03d6594efa5ef650d7ba/08a84/IFTTT_10.png 460w", "/en/static/b9ad12fb734c03d6594efa5ef650d7ba/e9beb/IFTTT_10.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b9ad12fb734c03d6594efa5ef650d7ba/e9beb/IFTTT_10.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "node-red-contacting-the-external-webhook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#node-red-contacting-the-external-webhook",
        "aria-label": "node red contacting the external webhook permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Contacting the external Webhook`}</h3>
    <p>{`Now we need to set up Node-RED to contact the webhook that we just configured whenever a local event is fired (we will be using a manual inject node to trigger it for now). The flow that we are going to build will consist of the following nodes:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/715c58801dda0fefd8021276bde5b3f6/e9beb/IFTTT_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABOElEQVQY042Ly0rDQBiFs3WRizspptR240rc6M438Wlc+BD2BdQK1WUVi2AWtiiFYrUxxF5siJBO5ppm0iYzkpaCO/34+Dlw/qPslsvlSsUsmrquq6qqaZq+xjByNw1jQ1P3twqnB0cne4fHhZ3tommaZqlUUnzfD3MAAGA8HgVBQDCGCIcov3iZISYE4RhTTqOIUEYZwdjzPCUAIJkvEEIsikSaxhGjPJFpLDgWMeJ8JjMuEsQTOktiygBjABKQpHNGqdJs9ZpPPdsdwxDY/ff7B+u5+xa7FulckU6NOBb0Xzzn5nt49+m26o2z60a1flt1Bq8hCBX7/OLjsjbpdhGlUkohMpmbSpnl/srrKhNiIaWcTqdKe/TYHloD38WILMdC/sXqB0Ko9F3bdp0vb4IQ+ud4Bef8B5RBVqcbAn9pAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/715c58801dda0fefd8021276bde5b3f6/e4706/IFTTT_11.avif 230w", "/en/static/715c58801dda0fefd8021276bde5b3f6/d1af7/IFTTT_11.avif 460w", "/en/static/715c58801dda0fefd8021276bde5b3f6/b6582/IFTTT_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/715c58801dda0fefd8021276bde5b3f6/a0b58/IFTTT_11.webp 230w", "/en/static/715c58801dda0fefd8021276bde5b3f6/bc10c/IFTTT_11.webp 460w", "/en/static/715c58801dda0fefd8021276bde5b3f6/87ca7/IFTTT_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/715c58801dda0fefd8021276bde5b3f6/81c8e/IFTTT_11.png 230w", "/en/static/715c58801dda0fefd8021276bde5b3f6/08a84/IFTTT_11.png 460w", "/en/static/715c58801dda0fefd8021276bde5b3f6/e9beb/IFTTT_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/715c58801dda0fefd8021276bde5b3f6/e9beb/IFTTT_11.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`First, `}<a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_and_MQTT/Introduction/#creating-your-first-flows"
        }}>{`copy and import`}</a>{` the following code into the Node-RED Admin Panel:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"87f67c26.0ff7b"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"inject"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b8ce5e67.23af6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"payloadType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"repeat"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"crontab"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"once"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"onceDelay"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`90`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`40`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"3b378299.bdbede"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"e8acbbcd.763f78"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http request"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b8ce5e67.23af6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Web Req"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"method"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"POST"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"ret"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"txt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"url"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"https://maker.ifttt.com/trigger/{{event}}/with/key/API_KEY"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tls"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`374`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`40`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"9fe779e1.db2bb8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"9fe779e1.db2bb8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"debug"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b8ce5e67.23af6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"active"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tosidebar"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"console"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"complete"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`521`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`40`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"3b378299.bdbede"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"function"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b8ce5e67.23af6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Req Params"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"func"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg.event = \\"test_request\\";\\nmsg.payload = {\\"value1\\": \\"testvalue1\\", \\"value2\\": \\"testvalue2\\", \\"value3\\": \\"testvalue3\\"};\\nreturn msg;"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"outputs"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"noerr"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`226`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`40`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"e8acbbcd.763f78"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`The Inject Node is set up to insert an empty string when clicked manually. It follows a `}<strong parentName="li">{`Function Node`}</strong>{` that has to set the `}<strong parentName="li">{`Event Name`}</strong>{` - needed to set the right URL for our IFTTT Webhook and a message payload that carries our three variables `}<strong parentName="li">{`Value 1-3`}</strong>{` that we want to display in the IFTTT Email notification:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`msg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`event `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"test_request"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
msg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"value1"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"testvalue1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"value2"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"testvalue2"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"value3"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"testvalue3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` msg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a0bb325d8bd6af34e5c92d88d9fd2715/e9beb/IFTTT_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABlklEQVQoz3XIy2rbQBgFYD1jsdxFHCl0UUoW2XaTlwh9goDpotBAaE2gqWTragcXLwxqA4UqNtbMNLokM7akuUoldgspJB+Hn3N+7ZVpGobR6/X0nY7e6XR0Xe/+87LbfaHrb/Z67w6PTl4fHu8Z+4axb5rmwYHmOI7v+0Hgu647n8+j6HsURT+ur5MkAY9AAG4hvEXoN4AQgNWveBXfaOPxeDabTafTyWSCIKSUVlVVP+Xvl7FNXnw7evvz9L12Nrj8+OnLpe24nrtc3ORZFsfxcrHAhKyfQ8g9Qndpqln9/td+37248MMQAIAxKatKSimVUk3zXNq2lVJqg9H5YHg+9KzADyBCZFMVeE2FEqrdhauGy//CZFNWNWNMs0a2NbIdz/U9HyGEMUnTlHHBhaCMUUa5EEIqIaSQim+vlIoxVte1Zn22rYHlDb0gDCCEeZYtF8skAcVdnhVJVqwwye5JWpaErPNNifE6xySv64pRqo2GZ7b1IQztq6sphJBzziivayqEaB80Tds0jdo21W77bgoh/gCmpchnUlQNhAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a0bb325d8bd6af34e5c92d88d9fd2715/e4706/IFTTT_12.avif 230w", "/en/static/a0bb325d8bd6af34e5c92d88d9fd2715/d1af7/IFTTT_12.avif 460w", "/en/static/a0bb325d8bd6af34e5c92d88d9fd2715/b6582/IFTTT_12.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a0bb325d8bd6af34e5c92d88d9fd2715/a0b58/IFTTT_12.webp 230w", "/en/static/a0bb325d8bd6af34e5c92d88d9fd2715/bc10c/IFTTT_12.webp 460w", "/en/static/a0bb325d8bd6af34e5c92d88d9fd2715/87ca7/IFTTT_12.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a0bb325d8bd6af34e5c92d88d9fd2715/81c8e/IFTTT_12.png 230w", "/en/static/a0bb325d8bd6af34e5c92d88d9fd2715/08a84/IFTTT_12.png 460w", "/en/static/a0bb325d8bd6af34e5c92d88d9fd2715/e9beb/IFTTT_12.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a0bb325d8bd6af34e5c92d88d9fd2715/e9beb/IFTTT_12.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Next we have a `}<strong parentName="li">{`HTTP Request Node`}</strong>{` that sets the URL for our IFTTT Webhook as a `}<strong parentName="li">{`POST`}</strong>{` message:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`https://maker.ifttt.com/trigger/{{event}}/with/key/API_KEY`}</code></pre></div>
    <p><strong parentName="p">{`Note`}</strong>{` that this is using the `}<strong parentName="p">{`Event Name`}</strong>{` that we set as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`msg.event`}</code>{` in the previous `}<strong parentName="p">{`Function Node`}</strong>{`. But to identify our IFTTT account we first need to find out our personal `}<strong parentName="p">{`API_KEY`}</strong>{` and add it at the end of the URL:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2dbd58632425c05f049b359fda990816/e9beb/IFTTT_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACG0lEQVQoz3WQ604TURhF+8oVmoZaCEpIYzCY+MsX0CCJCrGK0tJp59ZSegX5S4gJBTp07tOZzqXLdEANiZ5kZX/nJGdnfzuzks9TLBRYKRTI5/M8yT4hm82SW86xtLSUspzLUSwWWVtb4+nqKqX1dXa2ttl98ZI3zzZ4vrlJ6dU2G6USmU6nQ7/X5ex0yGAwYDgccn7+A208ZjKZYBgmhm7g+z5hGDKbzQhmM6ZBkOKHIZ6uc1URMC8uyTQkhcO6hCCriJKMLMsIgsD19Q2apjEajZiYJpbjYBrGIyzDwHYdtItLBluv+akek6kdHrG385H93T0aQoNms4kkiozHGoZhcHt7i3V1RWAYBFFEEIb3mhKm91kckwB+FJKRqgdUPryluvceqV7j5KTD2dkphq5j2TZEEdN6A1dokHgesWkRW48JLYt5HKeVZBotka/Sd77JFURFpH3cTrvUdR3XdSFJsESZO6HORG0yUVTuJIU7WU1ZvFlqC/yAMIrIyIJM9UuN2oGA1JBot+8NLcvCcZxULU3DvrnBGl3jaRpz1yVxHBLnQT2PxUkTymINofIZoVKm1VTodrv0+31s28bzvFRN28adTtNP8//wx1BRDqkevaNS3aHVkuj1+vQHfUzTTM3iJCHwfSzTTCtI4pg4iv7y0N18Pr83rAtHlMufKJf3UVWFXq+XrmwYemqYJEmadLH+Yl4Y/IvfCX8BiUDEdZOYRZoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2dbd58632425c05f049b359fda990816/e4706/IFTTT_13.avif 230w", "/en/static/2dbd58632425c05f049b359fda990816/d1af7/IFTTT_13.avif 460w", "/en/static/2dbd58632425c05f049b359fda990816/b6582/IFTTT_13.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2dbd58632425c05f049b359fda990816/a0b58/IFTTT_13.webp 230w", "/en/static/2dbd58632425c05f049b359fda990816/bc10c/IFTTT_13.webp 460w", "/en/static/2dbd58632425c05f049b359fda990816/87ca7/IFTTT_13.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2dbd58632425c05f049b359fda990816/81c8e/IFTTT_13.png 230w", "/en/static/2dbd58632425c05f049b359fda990816/08a84/IFTTT_13.png 460w", "/en/static/2dbd58632425c05f049b359fda990816/e9beb/IFTTT_13.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2dbd58632425c05f049b359fda990816/e9beb/IFTTT_13.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`To get access to our personal API key we first have to sign in to our IFTTT account and open the following page `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`https://ifttt.com/services/maker_webhooks/settings`}</code>{`. Copy the alpha-numeric key in the Account Info - e.g. if you find the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`URL: https://maker.ifttt.com/use/c3oL9WnAbz8Z08KumJWS`}</code>{`, then `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`c3oL9WnAbz8Z08KumJWS`}</code>{` is your API key:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e12543a5ecc5a3dee42a2e135a490a41/e9beb/IFTTT_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABoklEQVQoz5VSy27UMBSdH+MH2LJggcRPVFXVLUKIH2BJN/0ANlAhjQoSCyR2ozIRlJmqiV0nzsNOnE7ieGLfW008MO10pJYjS35cn3vuPfZoMpmMx+M4jheLBWMsDEMpS6UUIaRWdVVVPOHL5RLvous6rfXIT9ZaRAQANwAA+r7vrVta11uHAFtkGDAqy5ISmmaZlCKKojzP8dEYOef6vvdqbq27CvyU+OIUX37FL2y1dbCLfP/IDvdOQnzyAZ+e4Lvpqkz7X+QfGT4b4/NT/EgerQyDN0kSB79n337R7+fs7PwiTbmP7SbDLazErc2zjDPKGc2zTGu927D7ys659Xqo1uvBXazJflZKSSmFFJ0xmyzWOjv4by04B3+TbpR9FiFEnCQ85WVZqUpd19cA8PA7exndtkKIQhSc84v5nDJW0avPb96+39s/Pjg8Pjg82tv/9Oo11PU/Uzc9q0qlBWflvF4o0xkAsMYoelVchiKMRBgVl6EiFIdfjFtkznkwDWZ/ZtMgIISYofMHK1+TjTFN07Rt2zSN1tr/Vm/S7bFFvgGlvVzDcGdNcgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e12543a5ecc5a3dee42a2e135a490a41/e4706/IFTTT_14.avif 230w", "/en/static/e12543a5ecc5a3dee42a2e135a490a41/d1af7/IFTTT_14.avif 460w", "/en/static/e12543a5ecc5a3dee42a2e135a490a41/b6582/IFTTT_14.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e12543a5ecc5a3dee42a2e135a490a41/a0b58/IFTTT_14.webp 230w", "/en/static/e12543a5ecc5a3dee42a2e135a490a41/bc10c/IFTTT_14.webp 460w", "/en/static/e12543a5ecc5a3dee42a2e135a490a41/87ca7/IFTTT_14.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e12543a5ecc5a3dee42a2e135a490a41/81c8e/IFTTT_14.png 230w", "/en/static/e12543a5ecc5a3dee42a2e135a490a41/08a84/IFTTT_14.png 460w", "/en/static/e12543a5ecc5a3dee42a2e135a490a41/e9beb/IFTTT_14.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e12543a5ecc5a3dee42a2e135a490a41/e9beb/IFTTT_14.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Copy your API key and replace the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`API_KEY`}</code>{` part in the URL for the `}<strong parentName="li">{`HTTP Request Node`}</strong>{` in Node-RED. Then confirm the edit by hitting `}<strong parentName="li">{`Done`}</strong>{` and the `}<strong parentName="li">{`Deploy`}</strong>{` your flow:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/56ce1a9c118c8e56e4da4c88c6e6df53/e9beb/IFTTT_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACUUlEQVQoz3XPy08TURTA4fmvNISoa3fGlRvdujAxJgJJayBgo1gFZWihdKMsRJCQAG0JpY8ZZqYPnFdtbdp0UEIkMUTKzNyZuczzXkMp6MYvZ3E3v5xziRs3b10fGHz0+EliIfl2hiRjcXI2Ts7GZmZjZCyeWEjOJxZi83PJROLz+8XVj0uL88mp6Xe379y9NjBIvIxEopOT4xMT4XB4ZGRkaOjp8PBwKBQaHR19Fg6HQqGxsbHnkchUNLrx4lX+3v0P0Tevp6fnxsfjJElIoihLUrPRqNfrzea3VqvVbrcPDw8NwzR6IIRBEPgI2RDaBwcexrYGfjx4eMqWieIuu1Wgc7vsLsNyLJvfyW5spARR1DXtV496Sdd13bI0Xf/986i1vHYkfyXo7Vx6aTW9vMZQ9N7eXqlUKlJ0s9kEABwfH5+cnFiW6TiO2xvHts/fnhdgbLsuwVDZbGoll1llGUoQhEajLsvy/v6+ZVn22VmAkAWh5/t+EPQHIQ+eeTrwPI9gKuw2s5Nl80yJ5b/wtVqN53lFUUzTdFzH84NTDagaUHWjq+qqpqsm7Crf7XbHw5jgaK6QLhS3ihzD8fx5LAiCoii2bXe7XU3VVFUDhqHrwPV8jDFCOAgQxth1XaLMUXRhkyqkqtWyJEm1mnwVA2BAaAEAbNvG/0DoMi6Vc/nCp1x+pVrlJEmW5X4MexBCEEIAgOu6CKGgx+85/zNNF9Op9Ux6s1wpy/Lf2DTNixgAw3Gc3r6+IAj6mymKSqXSmcxWpVK5iEVR7HQ6+P+uzv4DFCFRWqz+axgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/56ce1a9c118c8e56e4da4c88c6e6df53/e4706/IFTTT_15.avif 230w", "/en/static/56ce1a9c118c8e56e4da4c88c6e6df53/d1af7/IFTTT_15.avif 460w", "/en/static/56ce1a9c118c8e56e4da4c88c6e6df53/b6582/IFTTT_15.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/56ce1a9c118c8e56e4da4c88c6e6df53/a0b58/IFTTT_15.webp 230w", "/en/static/56ce1a9c118c8e56e4da4c88c6e6df53/bc10c/IFTTT_15.webp 460w", "/en/static/56ce1a9c118c8e56e4da4c88c6e6df53/87ca7/IFTTT_15.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/56ce1a9c118c8e56e4da4c88c6e6df53/81c8e/IFTTT_15.png 230w", "/en/static/56ce1a9c118c8e56e4da4c88c6e6df53/08a84/IFTTT_15.png 460w", "/en/static/56ce1a9c118c8e56e4da4c88c6e6df53/e9beb/IFTTT_15.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/56ce1a9c118c8e56e4da4c88c6e6df53/e9beb/IFTTT_15.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Hit the `}<strong parentName="li">{`Inject Node`}</strong>{` to trigger an event. The `}<strong parentName="li">{`HTTP Request Node`}</strong>{` will contact your IFTTT webhook and you will see a `}<em parentName="li">{`success message`}</em>{` outputted into the debug panel from the `}<strong parentName="li">{`Debug Node`}</strong>{` connected to the request node - `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Congratulation! You fired the test_request event`}</code>{` - telling you that the IFTTT server was contacted and your Applet was executed:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a6a894941011fb658f8cc3b13627cbd9/e9beb/IFTTT_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABS0lEQVQY02XNy06DUBAGYBY1sX0AqjV05aa+hz6QW018nnavJsadumkTLDTVFmiBUw7XA4cGEFouY6jVaPwWM3/+ZDLMKcedcNzxUbt5WGv+02q1DhqNbrd7UTs/6/XabJtl2U6nwyCEbMtyKLUpXSgyNrFPiOt6rkc8j1iWjdBqJs0lRfEp9SlF2FCWS0lRZFlmgoAWAKkgboQJAGzzsqwAoIRqC+UGoKS+nxGSEpLtQuK4a4zTIIjjhHl8ER+munJ5HVzdjDT7/kkYTrVYf6WTu1C8jbCojUWf50NVpZKcYBzpKDZwvlhGhDByvy8NBibPhwEFgKpWL4D9NMZCMpt9mGZqmnm4LuO4SJJKR5HrMiPteag+qc6C0vXXMfzYZUfXc7QqTKvAZmFZpW2Xtl05ThJFzLsyf5PnK2zQ78/wW1WlWfa32kvT9BPyz0/20hYj9wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6a894941011fb658f8cc3b13627cbd9/e4706/IFTTT_16.avif 230w", "/en/static/a6a894941011fb658f8cc3b13627cbd9/d1af7/IFTTT_16.avif 460w", "/en/static/a6a894941011fb658f8cc3b13627cbd9/b6582/IFTTT_16.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a6a894941011fb658f8cc3b13627cbd9/a0b58/IFTTT_16.webp 230w", "/en/static/a6a894941011fb658f8cc3b13627cbd9/bc10c/IFTTT_16.webp 460w", "/en/static/a6a894941011fb658f8cc3b13627cbd9/87ca7/IFTTT_16.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6a894941011fb658f8cc3b13627cbd9/81c8e/IFTTT_16.png 230w", "/en/static/a6a894941011fb658f8cc3b13627cbd9/08a84/IFTTT_16.png 460w", "/en/static/a6a894941011fb658f8cc3b13627cbd9/e9beb/IFTTT_16.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a6a894941011fb658f8cc3b13627cbd9/e9beb/IFTTT_16.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Check your Email (the one you used to register your IFTTT account) - you will have received a message from IFTTT that follows the structure we set up in the Applet configuration:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/44420c964d4d3741725fc159356633fc/e9beb/IFTTT_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABJElEQVQY033Q206DQBAGYN7/hYxJLYk32oMXtdKmlZbDLlDoHmF3BoSwpiYmalq/zMV/Mcn8Ge/N91d398Fkun3wd/7jZjINJtP46TlbvJDZPJ3N6WJJ50uRElXXSkr1g5cEm3S1rnb7KtyT8JWEa5WmmBeQ5ZDl9jKZIfTDmH4Y+t+8YLtNCY3iJC3pjr7HJWmstYi1MbUxDQC03TiO7hqvYkwqzTjnUlaMMSGw69qug7bFrkNAROz7frjGcxactQ7AGfsdzFcG1zRuGNxtHsuK4hiRfUjDgywKKzhKCULWnPWMjUpftm7VljSrDhGPExYnLIolIYpm52NURlFbnC4t/rmsyxK4MIyjVIZxy7k+la3SIERTnbVSYME0jdYaEf987hNvE8JrtAWfFwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/44420c964d4d3741725fc159356633fc/e4706/IFTTT_17.avif 230w", "/en/static/44420c964d4d3741725fc159356633fc/d1af7/IFTTT_17.avif 460w", "/en/static/44420c964d4d3741725fc159356633fc/b6582/IFTTT_17.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/44420c964d4d3741725fc159356633fc/a0b58/IFTTT_17.webp 230w", "/en/static/44420c964d4d3741725fc159356633fc/bc10c/IFTTT_17.webp 460w", "/en/static/44420c964d4d3741725fc159356633fc/87ca7/IFTTT_17.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/44420c964d4d3741725fc159356633fc/81c8e/IFTTT_17.png 230w", "/en/static/44420c964d4d3741725fc159356633fc/08a84/IFTTT_17.png 460w", "/en/static/44420c964d4d3741725fc159356633fc/e9beb/IFTTT_17.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/44420c964d4d3741725fc159356633fc/e9beb/IFTTT_17.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "ifttt-contacting-a-node-red-endpoint",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ifttt-contacting-a-node-red-endpoint",
        "aria-label": "ifttt contacting a node red endpoint permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IFTTT Contacting a Node-RED Endpoint`}</h3>
    <ol>
      <li parentName="ol">{`We now want to set up a Node-RED sequence that contacts an IFTTT Webhook - just as in the step before - but instead of sending an Email, we want IFTTT to contact our own Webhook in Node-RED and trigger an event there that we can consume in a Flow. `}<strong parentName="li">{`Note`}</strong>{` that this is just a proof of concept - later we want to use one of the many IFTTT services to replace the manual trigger, enabling us to connect the Node-RED webhook to services like `}<em parentName="li">{`Amazon Alexa`}</em>{`, `}<em parentName="li">{`Google Home`}</em>{`, `}<em parentName="li">{`Nest Home Automation`}</em>{`, etc.`}</li>
    </ol>
    <p>{`To set up the manual trigger, we could simply recycle the node sequence we build previously. But to explore a different approach, we are going to `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Node-RED/#the-node-red-admin-panel"
      }}>{`install a third-party node`}</a>{` called `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/node-red-contrib-ifttt"
      }}>{`node-red-contrib-ifttt`}</a>{` - that basically just combines the `}<strong parentName="p">{`Function Node`}</strong>{` and `}<strong parentName="p">{`HTTP Request Node`}</strong>{` that we used earlier (`}<strong parentName="p">{`Note`}</strong>{` with this setup, we cannot send custom variables with the message payload - we still would have to add another function node to realize the same sequence we created earlier.):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2efd9020.529d9"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"inject"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b8ce5e67.23af6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"payloadType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"repeat"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"crontab"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"once"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"onceDelay"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`92`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`139`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7abb924d.fcfabc"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7abb924d.fcfabc"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ifttt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b8ce5e67.23af6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"eventName"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ifttt_webhook"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"d6390bcb.c9fc08"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`236`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`139`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"d6390bcb.c9fc08"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ifttt-key"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d24b3fb94f13d4a156397918d28b3958/e9beb/IFTTT_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.043478260869563%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABAUlEQVQY002NPU+DQABA+fkOTYfG1UmjDUYcGokMpjUYaI/CyUERoRLARriWz0NSNXCY1mp8eevLY+JNFrxtvNfYj1JCym0aVtirsPeOXz7yqK4LQlZZ5ue5X9frdba2fNMOrCffsv0FI84XvKizwvRGskLPrc1RAi4SZZjMzrbuJMZuEAjPNuc4VziSJfTQvzwasL0B2zvm+kypPWIZpEBdSUqioVLTU3VnDo3wXi6g0VLaNO2PlNK2aQ80LTOeW9eCfMrdDW+BvQwUO+BnJj9FE315cj7yxmLXdc2+oH/RwZYhKkyBmimwUOAnMiq4mxOISogKoH45Du2639f+/I9vdbYFm7XNZNgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d24b3fb94f13d4a156397918d28b3958/e4706/IFTTT_18.avif 230w", "/en/static/d24b3fb94f13d4a156397918d28b3958/d1af7/IFTTT_18.avif 460w", "/en/static/d24b3fb94f13d4a156397918d28b3958/b6582/IFTTT_18.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d24b3fb94f13d4a156397918d28b3958/a0b58/IFTTT_18.webp 230w", "/en/static/d24b3fb94f13d4a156397918d28b3958/bc10c/IFTTT_18.webp 460w", "/en/static/d24b3fb94f13d4a156397918d28b3958/87ca7/IFTTT_18.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d24b3fb94f13d4a156397918d28b3958/81c8e/IFTTT_18.png 230w", "/en/static/d24b3fb94f13d4a156397918d28b3958/08a84/IFTTT_18.png 460w", "/en/static/d24b3fb94f13d4a156397918d28b3958/e9beb/IFTTT_18.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d24b3fb94f13d4a156397918d28b3958/e9beb/IFTTT_18.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Double-Click the `}<strong parentName="li">{`IFTT Node`}</strong>{` to add an `}<strong parentName="li">{`Event Name`}</strong>{` - we choose `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ifttt_webhook`}</code>{` -  and click on the pencil icon to add your `}<strong parentName="li">{`API key`}</strong>{`, that we determined earlier:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/68f6f25d107f04e60f9a1eadc4fccf80/e9beb/IFTTT_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABeUlEQVQY033Lz07bQBgEcD8hCZeESH0Pzn2GHjhVQoKql0qkEkog3vW/xMYGr9cVbYMgquNLggQk2LvJ7hfHXhRCpR5QfxrNaUZrNpofWnt7rVaj2dip1bZ5U69v8q7d3Vq9rukIG9iwLMu2LIzQ+fkZxiimNIoIjaLh72GapuN/pek4Sf7c3CSjkXbhXwa+TyklYXgVhn3PN/vebD4HAAkgJcDf3loVBZ/Nyf7H5Os3Ddtuu4PaHYQM23Nd03F1w5lO76eTSZIk08lkseCcM84Z44zlOSvLzLQfPh08M6Y5Zz18/EU/OrY73Ysg+BFvZFnGGZNSMiG5XDEBQgDIFSwlKJV39cXnw0opzRyYXaPdwSemg1zX6+rGyWnv8WkmhViXFR/ePvjh4yVZzJ6hWAOsQMISikJtaI7nYsdADu67/SAISBSFhOR5LoTgy6W4G2XX1/znr0qISqlqqyyr9Xpzts0BPkW9772BNSARiSmNY8o5V69T9V8vA6h/qrhfw7wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/68f6f25d107f04e60f9a1eadc4fccf80/e4706/IFTTT_19.avif 230w", "/en/static/68f6f25d107f04e60f9a1eadc4fccf80/d1af7/IFTTT_19.avif 460w", "/en/static/68f6f25d107f04e60f9a1eadc4fccf80/b6582/IFTTT_19.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/68f6f25d107f04e60f9a1eadc4fccf80/a0b58/IFTTT_19.webp 230w", "/en/static/68f6f25d107f04e60f9a1eadc4fccf80/bc10c/IFTTT_19.webp 460w", "/en/static/68f6f25d107f04e60f9a1eadc4fccf80/87ca7/IFTTT_19.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/68f6f25d107f04e60f9a1eadc4fccf80/81c8e/IFTTT_19.png 230w", "/en/static/68f6f25d107f04e60f9a1eadc4fccf80/08a84/IFTTT_19.png 460w", "/en/static/68f6f25d107f04e60f9a1eadc4fccf80/e9beb/IFTTT_19.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/68f6f25d107f04e60f9a1eadc4fccf80/e9beb/IFTTT_19.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Just as in the `}<a parentName="li" {...{
          "href": "#ifttt-setting-up-a-webhook"
        }}>{`previous example`}</a>{`, we now create an Applet on IFTTT that will use a `}<strong parentName="li">{`Webhook`}</strong>{` to listen to the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ifttt_webhook`}</code>{` event. Go to the `}<strong parentName="li">{`My Applets`}</strong>{` tab on IFTTT and click on `}<strong parentName="li">{`New Applet`}</strong>{`. Click on `}<strong parentName="li">{`this`}</strong>{` and choose a `}<strong parentName="li">{`Webhook`}</strong>{` as trigger:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/404347ed96ef6ba40288ee315200cd70/e9beb/IFTTT_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "106.08695652173911%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACQUlEQVQ4y41UTWsTURR9iQkxabU1JqZp0zSNmpomaQZBSoviXhDc6MKt21qKS5G6cOlS1HzMZDKT+crMSFq/KIK4Uv+E4EqUKoiUNlWmc+RGUHAxk+Fxubx5551z75w7DIPHdSn2HVQsnOkio2BKwQkZCQmTHaQ7mOiAPabNr306eTA4z/4DczYKBvIachpmNEwqyKqUFwyEGgT+5gGu2sRWsjBv4pROmLJJWgoGYoIfuGJhWkWpixkVJ3XMqpjVUOwSPszTKy/ZVYuozm9geROLPVzYxNIGzvVw6SWyml/NVRtHWnQo3cG4iKMijrUxJiIpDVEzZyMuIa9TwVEerAZWpxWoU7d9ZFcsoiqZKFvgnhB5qEm3RHmEm9T2bW/ZMQEpGYk2raREVYQaiPBE7sW87xDhuEitzqpUdkahi+ISxUDds2H7A9nsAYI1qpD9jTUEG5RnVD9wYsAzIZOEpIR4m3ZiPNgjEuJjT84mY+RUumJKIUBOw6gwHDgpY8HGaYN6XjTJXmUTc8PYk7NpjKo25gy3qDlF3eG6TlFzIk3Xn/nPYByugwlgCpgM1qEYqA1ke5tkwUJKcnMmzt7/cP1m7+ra82urT6/c2pqX9qIiphXXBzwiuMvPcLn2cfX2i5W7r9bWt26sv75o7o62fafKxkgLKQkRGUzHIQPBLiXhNjk86wuOCfTTSUsHWenX8ZZz5813/v2nh+8+33v7o2zhy67nYIyJ9EkSEjkkKqCi/1wydhaNnbLWz+vY3vsH/g0n6sKBP9iqMgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/404347ed96ef6ba40288ee315200cd70/e4706/IFTTT_20.avif 230w", "/en/static/404347ed96ef6ba40288ee315200cd70/d1af7/IFTTT_20.avif 460w", "/en/static/404347ed96ef6ba40288ee315200cd70/b6582/IFTTT_20.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/404347ed96ef6ba40288ee315200cd70/a0b58/IFTTT_20.webp 230w", "/en/static/404347ed96ef6ba40288ee315200cd70/bc10c/IFTTT_20.webp 460w", "/en/static/404347ed96ef6ba40288ee315200cd70/87ca7/IFTTT_20.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/404347ed96ef6ba40288ee315200cd70/81c8e/IFTTT_20.png 230w", "/en/static/404347ed96ef6ba40288ee315200cd70/08a84/IFTTT_20.png 460w", "/en/static/404347ed96ef6ba40288ee315200cd70/e9beb/IFTTT_20.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/404347ed96ef6ba40288ee315200cd70/e9beb/IFTTT_20.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`You have to give this `}<strong parentName="li">{`Webhook`}</strong>{` the `}<strong parentName="li">{`Event Name`}</strong>{` that you choose in Node-RED in the previous step and give it the URL to your Node-RED server. This can be done via the WAN IP address (`}<strong parentName="li">{`Note`}</strong>{` that this usually changes every 24hrs) or via your `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Remote_Access/"
        }}>{`INSTAR cameras DDNS address`}</a>{` (as long as it is on the same network as your Node-RED server!). This is followed by the port used by your `}<a parentName="li" {...{
          "href": "/en/Software/Linux/Node-RED/#configuring-node-red"
        }}>{`Node-RED installation`}</a>{` - by default this is the `}<strong parentName="li">{`Port 1880`}</strong>{`. `}<strong parentName="li">{`Note`}</strong>{` that this port needs to be `}<a parentName="li" {...{
          "href": "/en/Internet_Access/Port_Forwarding/"
        }}>{`forwarded in your router`}</a>{` for IFTTT to be able to access it! We complete the URL by adding `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/pushalarm/trigger/recording`}</code>{` to identify the Node-RED webhook that we will have to create later.`}</li>
    </ol>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`We can also add a message payload to the webhook request by IFTTT - this way we will be able to use the same webhook in Node-RED for more than one IFTTT Applet - e.g. when I leave home `}<strong parentName="li">{`Applet 1`}</strong>{` sends the payload `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val": "on"}`}</code>{` to activate the Motion Detection on my IP cameras. But if I arrive home and you receive `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val": "off"}`}</code>{` from `}<strong parentName="li">{`Applet 2`}</strong>{`, switch it off again. For this we have to switch to the `}<strong parentName="li">{`POST Method`}</strong>{`, choose the `}<strong parentName="li">{`JSON Content Type`}</strong>{` and the value that we need - like `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val": "on"}`}</code>{` :`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6b0bbf4d9e840cfd1c2dfb102510c1b6/e9beb/IFTTT_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.8695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB40lEQVQ4y5WTTWsTURSGb9MY01ap1AiptWArMS0FQfBniKArRej4gQv/hhtBUErBTJJJYj6mSSdoceHCKuk0EdxIFy5FoVSxuujCD6SC6Tzl3KTEhWbS4eXCwHnmvu85ZxT6+bMjp+ESshhfIJxBJVGpjgJJ1EMaX6Ss6bUg1N/w7ApDOc4tMVrkgMXBTEeRAsrsCl9zUQlOO0RLBNIErY5O2OKlvvl/2HAZyBJ3GLMZLXFcK1pkrMSxgnzX72aT4Uc6Z3JPOnN/2i+z4dJvEVtkosxJrWmHSJ6+FMFeYJUSIO4w5RBfFNvhjIwgWhRTdR84yUyVWIXJspyRghgOWdJ/gTf9Mo/k21NVZttwMN1b5kBaptJq9VBuPw2bdSXhmcdMVyXzkXx7VUIZBrO9jCohQx7JC6nTerJhFoeyfpkNV66KVWRUM1XZs9sr20/WPuZebzx9+/VU2Vv+1BXuSzFuM6D3WZnecNabLHsTdjNW8XzW8/oqal7vg0nYImJzuKDbniBg+jXsZl2XJr2zS3z/9fvdh/Xtb1vPNqTng2KEV13gKy9R91BzHLW4u9a809iae/Pzag31gOA86j6Nz/+CW+8L77lc41ZDfuzzz7lY48ILLi2Loxt1jFXWf0jZzh68C4ACPCCRhom1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b0bbf4d9e840cfd1c2dfb102510c1b6/e4706/IFTTT_21.avif 230w", "/en/static/6b0bbf4d9e840cfd1c2dfb102510c1b6/d1af7/IFTTT_21.avif 460w", "/en/static/6b0bbf4d9e840cfd1c2dfb102510c1b6/b6582/IFTTT_21.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6b0bbf4d9e840cfd1c2dfb102510c1b6/a0b58/IFTTT_21.webp 230w", "/en/static/6b0bbf4d9e840cfd1c2dfb102510c1b6/bc10c/IFTTT_21.webp 460w", "/en/static/6b0bbf4d9e840cfd1c2dfb102510c1b6/87ca7/IFTTT_21.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b0bbf4d9e840cfd1c2dfb102510c1b6/81c8e/IFTTT_21.png 230w", "/en/static/6b0bbf4d9e840cfd1c2dfb102510c1b6/08a84/IFTTT_21.png 460w", "/en/static/6b0bbf4d9e840cfd1c2dfb102510c1b6/e9beb/IFTTT_21.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6b0bbf4d9e840cfd1c2dfb102510c1b6/e9beb/IFTTT_21.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`We now created an IFTTT Applet that, when triggered, will try to contact a webhook (`}<strong parentName="li">{`HTTP IN Node`}</strong>{`) in our Node-RED installation - make sure that the URL is reachable from the internet before you continue (see `}<a parentName="li" {...{
          "href": "/en/Internet_Access/Port_Forwarding/"
        }}>{`Portforwarding`}</a>{` in step 4). Let's create that HTTP Endpoint in our Node-RED Flow:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"988f4c1b.cfac3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http in"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b8ce5e67.23af6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"IFTTT Trigger"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"url"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/pushalarm/trigger/recording"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"method"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"post"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"upload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"swaggerDoc"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`86`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`238`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"650a9d50.1e9294"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"df518ee6.55111"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"df518ee6.55111"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http response"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b8ce5e67.23af6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`242`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`239`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"650a9d50.1e9294"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b8ce5e67.23af6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"action"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"pretty"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`242`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`203`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"c2531a9f.8b5378"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"c2531a9f.8b5378"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"debug"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b8ce5e67.23af6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"active"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tosidebar"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"console"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tostatus"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"complete"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`381`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`203`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/be8c04d0fd655d82c370547cd92ebd18/e9beb/IFTTT_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABKklEQVQY003Q3U+CUAAFcP/tHnppPfQv9PHWo7a5aq2acykjNDHGcM6vEORe0ETuRUDkfrbJys77bzvnVBBaBIHqOE0PKNttmOdFkuVRnEZxusuLXUFwlm/zAidplmVSSvEbSmklDGeTyaNl1YbDe4xXEYr1wexFNevN/sj2xnCtjUHvK1AteziHJZaHMMYqHpj39FZPbxvmO4ALhJCUklBOmeBCUs7dFZhCe+bP7cDexKEQ/Ih9AM3+p/GhW4bpQ3+zWVOW7PeoKBBlKcbRXePqsnp+Xbu4fb7pDjRCyRGPXLemqFVFrWsdB/irZXnB63T65Ljt8NtPlq2l/YC9N0HSg/pXuw+D07Z20midKZ2RB+IoklIwRjinnFMpBKc7TlJGMsHZ3+AS/wAWt0SxWCjz0wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be8c04d0fd655d82c370547cd92ebd18/e4706/IFTTT_22.avif 230w", "/en/static/be8c04d0fd655d82c370547cd92ebd18/d1af7/IFTTT_22.avif 460w", "/en/static/be8c04d0fd655d82c370547cd92ebd18/b6582/IFTTT_22.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/be8c04d0fd655d82c370547cd92ebd18/a0b58/IFTTT_22.webp 230w", "/en/static/be8c04d0fd655d82c370547cd92ebd18/bc10c/IFTTT_22.webp 460w", "/en/static/be8c04d0fd655d82c370547cd92ebd18/87ca7/IFTTT_22.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be8c04d0fd655d82c370547cd92ebd18/81c8e/IFTTT_22.png 230w", "/en/static/be8c04d0fd655d82c370547cd92ebd18/08a84/IFTTT_22.png 460w", "/en/static/be8c04d0fd655d82c370547cd92ebd18/e9beb/IFTTT_22.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/be8c04d0fd655d82c370547cd92ebd18/e9beb/IFTTT_22.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The sequence starts with an `}<strong parentName="p">{`HTTP IN Node`}</strong>{` that expects a `}<strong parentName="p">{`POST Request`}</strong>{` on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/pushalarm/trigger/recording`}</code>{` - that is the URL we added to our IFTTT Applet as the webhook that needs to be contacted. We need to add an empty `}<strong parentName="p">{`HTTP Response Node`}</strong>{` at the end for the webhook to be operational:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c64475c1a2b4996fca47c18720a50e2/e9beb/IFTTT_23.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABp0lEQVQoz13O62/SUBjH8f65QEIzKLhoMv1nNNEl4iXFjVtpe07LoXTKQF1MzG660VDKmS2tPZybob5Z+Lz65Um+yaPUDw6eaJqmNWq1eqVSKZVK5XJZVdVqtaqqar1Wa+zRtEazefj8qPnsqXJWmBWm0+nn3T4Pl8swDFdRlGUZpXT7CGWUpFn0ZRbf/FJsAA3TBu7IBtAG4KRnfPjUWwQBxuuogDF+eCxOwpvb8eGLH8fvFMOwWi299Vbv9QyEkOf5yJuEq3Bxf399db1YLv9ut+meLMs5zwhR3G7HfP1y+OYV6Jy6CKGxNxp762iNMaZC5N8u4tGYpxkXglHKC4wxSogUQrGQpcO2DtpDZ+g4bndg6qeDP3EspRBC5BjHd3ebTZrmuZBS/L8WpJSKAx2jbxh9A0Lo+/7sfD6ff02ShBAipcy+X2BnhKG7hk5sAbpacSkZY5zzXQxNe9Du9vWOC1x/4kLj2LVaUbTE+GEXB8Hq52V0ebVZBMntb7pJGeeUUsbYLkbIBOCjbb9HyPInvu+BM99NkpgQIjhnUhLOCaOysPf2P2QJwVeOeRXzAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c64475c1a2b4996fca47c18720a50e2/e4706/IFTTT_23.avif 230w", "/en/static/4c64475c1a2b4996fca47c18720a50e2/d1af7/IFTTT_23.avif 460w", "/en/static/4c64475c1a2b4996fca47c18720a50e2/b6582/IFTTT_23.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c64475c1a2b4996fca47c18720a50e2/a0b58/IFTTT_23.webp 230w", "/en/static/4c64475c1a2b4996fca47c18720a50e2/bc10c/IFTTT_23.webp 460w", "/en/static/4c64475c1a2b4996fca47c18720a50e2/87ca7/IFTTT_23.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c64475c1a2b4996fca47c18720a50e2/81c8e/IFTTT_23.png 230w", "/en/static/4c64475c1a2b4996fca47c18720a50e2/08a84/IFTTT_23.png 460w", "/en/static/4c64475c1a2b4996fca47c18720a50e2/e9beb/IFTTT_23.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c64475c1a2b4996fca47c18720a50e2/e9beb/IFTTT_23.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Beside the  `}<strong parentName="p">{`HTTP Response Node`}</strong>{`, we also connect a `}<strong parentName="p">{`Debug Node`}</strong>{` that receives the payload that we set in the IFTTT Applet - the JSON payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val": "on"}`}</code>{` will be received as a JavaScript Object that can be used by following nodes.`}</p>
    <p>{`We can now test the chain that we build. Clicking on the `}<strong parentName="p">{`Inject Node`}</strong>{` will contact the `}<strong parentName="p">{`IFTTT Webhook`}</strong>{` that `}<strong parentName="p">{`IFTTT Applet`}</strong>{` is listening at. The Applet, when triggered, will then contact the `}<strong parentName="p">{`HTTP IN Node`}</strong>{` in our Node-RED Flow and transmit the JSON payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val": "on"}`}</code>{`, as seen in the debug panel.`}</p>
    <p><img parentName="p" {...{
        "src": "/en/6fbfbba698f823266aeb72489e564f88/IFTTT_24.gif",
        "alt": "IFTTT"
      }}></img></p>
    <h2 {...{
      "id": "real-world-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#real-world-example",
        "aria-label": "real world example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Real-world example`}</h2>
    <p>{`We are now able to trigger IFTTT Applets from a Node-RED Flow, as well as trigger the Flow through an IFTTT Applet - which opens up the whole variety of `}<a parentName="p" {...{
        "href": "https://ifttt.com/services"
      }}>{`services IFTTT`}</a>{` offers to automate our INSTAR IP camera.`}</p>
    <h3 {...{
      "id": "deactivate-the-alarm-when-i-am-home",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#deactivate-the-alarm-when-i-am-home",
        "aria-label": "deactivate the alarm when i am home permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deactivate the Alarm when I am Home`}</h3>
    <p>{`IFTTT offers an `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=com.ifttt.ifttt"
      }}>{`Android`}</a>{` and `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/jp/app/ifttt/id660944635?mt=8"
      }}>{`iOS`}</a>{` App that can use the sensors on your phone to trigger your IFTTT Applets. For example, create a new Applet, choose `}<strong parentName="p">{`this`}</strong>{` to add a trigger and search for `}<strong parentName="p">{`location`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/15e96fcb0897b75ad05fdef7eaf964a6/e9beb/IFTTT_25.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABtklEQVQoz6VSTUvcQBjOZaFQhJ48iof6V3oSCj34EwQPBaGXgv9BemhLESoo4gf1KPRQaBstu7ZNokXWbHT9SFi36iSbnd3Z2WSTmadMVmWbWi++PLwzw7zPOw/PvBruEVruHMcx8X3e6TDG4ijinDPGKG11u9EtZAkICZWFAOD7ZFPXLcsqlrYd58C0LNM0DMMo2xWoSimhitUy8LLMdRVJL4kjkfTuks0TlEM4oah4bcdr2W5rnyR2Q9qBUHkAlRAVVYlygDOWkb0O9JPmcdmpftra0bf3P35ueOehgN+VQYQ+mjHoNdo9XHIUzzNyEOFLLf1xQkuWu2m6RePU8rhNcURllaJKcdDELx+7RCr4av/zUuVb3O47YRIsHWL9CCtVbLiIYgGpzJK9VKZCDn5V5t5VJKm6mtShzaLwBtorjC7KIAUaQVqr37TPuX0VGRfPv0F7i+EFFObk4w8gpR32ZDx8OsFevOSv392IzJOTjDy1pchjq3j4Xo6uoTY9ExaGyPAIefCIjj/Dtdp/yGpSMPk1LzuZX+CLy6L+Oz2r/3c8+2a4bXy/gEWUsXsBBjz6a0j+ABULx8eeSd5BAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15e96fcb0897b75ad05fdef7eaf964a6/e4706/IFTTT_25.avif 230w", "/en/static/15e96fcb0897b75ad05fdef7eaf964a6/d1af7/IFTTT_25.avif 460w", "/en/static/15e96fcb0897b75ad05fdef7eaf964a6/b6582/IFTTT_25.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/15e96fcb0897b75ad05fdef7eaf964a6/a0b58/IFTTT_25.webp 230w", "/en/static/15e96fcb0897b75ad05fdef7eaf964a6/bc10c/IFTTT_25.webp 460w", "/en/static/15e96fcb0897b75ad05fdef7eaf964a6/87ca7/IFTTT_25.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15e96fcb0897b75ad05fdef7eaf964a6/81c8e/IFTTT_25.png 230w", "/en/static/15e96fcb0897b75ad05fdef7eaf964a6/08a84/IFTTT_25.png 460w", "/en/static/15e96fcb0897b75ad05fdef7eaf964a6/e9beb/IFTTT_25.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/15e96fcb0897b75ad05fdef7eaf964a6/e9beb/IFTTT_25.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the `}<strong parentName="p">{`Location Trigger`}</strong>{` and choose if you want this trigger to go off when you enter an area or when you are leaving it - in the end we will have to add two applets for both cases. They can be combined into one Node-RED sequence later on. Choose `}<em parentName="p">{`Enter Condition`}</em>{` for now and point it to your home:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8d212b34f2976590466c6da7c1804613/e9beb/IFTTT_26.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100.8695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACm0lEQVQ4y41TS0xTURAdC8EYPoqAEhEUMAqI0Y2JRVeKe3eCW78LV8bf3o0rP3xLS8trS1soBCFiYvxEYENNTBUD/SG0gKIBArSFFKF9x8ztg7IwwM3kvJd5c2bmnZlLECcmM/ZN44kTryf4XZax7aH4Yy3GeHcAuXq56j0mRkecQ9/dbo/X43G5XA6Hw+l0BoMhkTSRlWQgKuOvIOvcONuFp9+wODfjGxv/MTbun5j8MzP7xfl12OVejnDUWkxpM1H5f2f7vmkliskwAmEEQgr+XMLUkjwXQXgVgZAcCMn+YMwflCfDmF7G1BIWV5TcZB9DRgsKrWz5Fhy1Yr8E0uByL+4N8qciG44If64J6QZQI270KzKR0YsUHUcUWHC4lTFND6qDugvX+/i90MrOAgsnTdKCXqD6HZNXYyCzD7ubcaIdx9s4rljUSdFB/Qq3B5CqR0k7StrYWWTD6U72XP2wTjb5uJkznciRkGZApoQ9zaBaqLuZTBr2pBuwtwUZBmQZQQ2o2iBbR0H1UGlBTRxKGtFbPSq6cWeAs6jW/aRBshb0Etc+bmo7EdHIqNKAanCuC7f6+eeVvMKSdfypeqNytx+ZLchvRV4r8syM+Rbu8Mpb3B/ELi32Sax53OKDuLmhtmuegx462B4JfPwZDwbROILZCIbn4V5ImGeBPb+XleUnkxdJTcg144CJVTloQpbEP1Zm48a2XjSSvDhkxoUe5BiRbWRytpEFK7djJcprvCbz8m+2xG6bfSxDoY3HWCzwmI3HU9q+g8omH8/9pB3lHVw2U+IWqAFl9h2Qec4aVjJVLybcJLABpzp2QDZ6Qc+gquEBUq2YeR3oOUotyiXfivzpF873oLIXl3pxUVjlG1T08NWJC7MF+R8cR7FbDI+zwwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d212b34f2976590466c6da7c1804613/e4706/IFTTT_26.avif 230w", "/en/static/8d212b34f2976590466c6da7c1804613/d1af7/IFTTT_26.avif 460w", "/en/static/8d212b34f2976590466c6da7c1804613/b6582/IFTTT_26.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8d212b34f2976590466c6da7c1804613/a0b58/IFTTT_26.webp 230w", "/en/static/8d212b34f2976590466c6da7c1804613/bc10c/IFTTT_26.webp 460w", "/en/static/8d212b34f2976590466c6da7c1804613/87ca7/IFTTT_26.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d212b34f2976590466c6da7c1804613/81c8e/IFTTT_26.png 230w", "/en/static/8d212b34f2976590466c6da7c1804613/08a84/IFTTT_26.png 460w", "/en/static/8d212b34f2976590466c6da7c1804613/e9beb/IFTTT_26.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8d212b34f2976590466c6da7c1804613/e9beb/IFTTT_26.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we have to select a `}<strong parentName="p">{`Webhook`}</strong>{` as the `}<strong parentName="p">{`that`}</strong>{` condition - just as we have done in the earlier example. We will create a new `}<strong parentName="p">{`HTTP IN Node`}</strong>{` listening on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/alarm/md/areas`}</code>{` in Node-RED for it - the URL for the webhook will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`https://<DDNS Address>:1880/alarm/md/areas`}</code>{` accordingly. The Applet has to use this URL to `}<strong parentName="p">{`POST`}</strong>{` the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"off"}`}</code>{` message:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "731px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9500fb7dcda8bdf43a15c3673d8ccdcc/6e9ba/IFTTT_27.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "126.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAIAAAC+dZmEAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADjUlEQVQ4y3VUXUxbZRg+dRC0lLJRWpTBBTjBwSyZCcb4sxtd6Cb70y3OmyXbEn8To17oTKYxXumFDpiU9rSn56d0lLbMZMuGbLIVAtPErCSk0Q0Qp5maNjA4p53055zvMd8ZS4h4vrw5yZfzvud5n/d53sOoBADejKNxAB9OFm7dmJ784cd4PD4+Nj6VSExPT1+5ejWZTE5MTIxeGU1cTxA9/95hNP1y+Ta+mMJPaZBiLp/Py/JyRlEyGSWfy+VyK8VCQVWL+Rx9hTWHWXsh90MlpKBqBVUja4HWnVXk3zPYPoT2s2iJEGeUtEWJM4q2GJrD5NEB0hzGwxKOx+mXtfVtzy7DEcTT36LhDFoieHwQT0SxLYoyFowbJR4wXei4QDPV9cVzMmqDtKAlAmcU22NojaI1QsHbh9AUxgYWe76jmf+D/KuMapHCNoXRfEazcarVr1p9qsWvVQRQyYPxYNdFA+RZGTUSavvBeGEKgQmB6defPG17gxdMD1wXjduukeh4GqTi8XeHPj7m+eiNwMmj7kOfX2s+i9ZBUs6hc9ig7XkZVQKcofyL4ez7n428/c7Aa2/Fjr0eajs5ZRLwEEeYXnQacZ6TYRcp4a2DpP4cqs+hLAZTFIfH1BNj2RPj2SOXVg59b4SsoJxDBQ+bBLugOQSt1KsxHnJkOPPBSOq94b9ePS8fvGyM7JDgDBc3C0UTixIfSvUwufWBecCcgstAZzKTQaMv6+r97QX3H/Xf3DELsAbIPZEqeVItEgtHjHQmN7No6ruz76vknu6bj3X9XSbCyhGLXlwRoE05JOw1NIkCC0dsbM7uy9kFrUYij0jELq6W1en6G9pzXkZ5AGYJFRLKRTCsHl5qLMajm6Tb2CSzCuqFQgef2ikuuqQFp3ex9XTK4VuxCLDysIko8+MlA5OQmSy29C29fCrpEtM72T93iemDPT83dqVKBZj9ZJNAnb9vxEgqBTau2O5NbetNN3TTaOxOV7F5iz4wuhh9xosxL8PM48EgGP/9YFcJr3LuMdQZMzIa+Nzz4lK9W6nrXd7szW7isVEgNgHVAqkRSQlLdhtxvpHBU6dv7/862eG+9Urf3I4vf7FQe9B/AF1SvYsDRpxnFdQF8s9xC8/yi88EFp4M3q0NwS7h02v/cIkl//WlTyazh0eNV9IcQGUQVf2wBrFRwgO6yDtidztji7sj6faBzP5L/y3+F/JIerp4gKupAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9500fb7dcda8bdf43a15c3673d8ccdcc/e4706/IFTTT_27.avif 230w", "/en/static/9500fb7dcda8bdf43a15c3673d8ccdcc/d1af7/IFTTT_27.avif 460w", "/en/static/9500fb7dcda8bdf43a15c3673d8ccdcc/8ebaf/IFTTT_27.avif 731w"],
              "sizes": "(max-width: 731px) 100vw, 731px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9500fb7dcda8bdf43a15c3673d8ccdcc/a0b58/IFTTT_27.webp 230w", "/en/static/9500fb7dcda8bdf43a15c3673d8ccdcc/bc10c/IFTTT_27.webp 460w", "/en/static/9500fb7dcda8bdf43a15c3673d8ccdcc/feeb6/IFTTT_27.webp 731w"],
              "sizes": "(max-width: 731px) 100vw, 731px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9500fb7dcda8bdf43a15c3673d8ccdcc/81c8e/IFTTT_27.png 230w", "/en/static/9500fb7dcda8bdf43a15c3673d8ccdcc/08a84/IFTTT_27.png 460w", "/en/static/9500fb7dcda8bdf43a15c3673d8ccdcc/6e9ba/IFTTT_27.png 731w"],
              "sizes": "(max-width: 731px) 100vw, 731px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9500fb7dcda8bdf43a15c3673d8ccdcc/6e9ba/IFTTT_27.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Repeat those steps for a second Applet that posts `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"on"}`}</code>{`, whenever you leave the area.`}</p>
    <p>{`We can now use a variation of the Alarm Flow that we `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/#setting-up-node-red"
      }}>{`already used a couple of times`}</a>{` to switch the alarm areas of our INSTAR IP camera on and off when the message `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"on"}`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"off"}`}</code>{` is posted to our `}<strong parentName="p">{`HTTP IN Node`}</strong>{` webhook - you can `}<a parentName="p" {...{
        "href": "/en/Node-RED_Flows/nodered_flow_alarm_areas_IFTTT.json"
      }}>{`download the Flow from here`}</a>{` and add your camera login to the `}<strong parentName="p">{`Login Node`}</strong>{`. When the IFTTT Applets triggers our Node-RED webhook, the CGI commands for activating or deactivating the alarm areas will be send to your camera. `}<strong parentName="p">{`Note`}</strong>{` that the commands used here only work with HD and Full HD cameras and you can replace them with every `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`other CGI command you need`}</a>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/74071eeb6517cc54e3502dc30222cbfa/IFTTT_28.gif",
        "alt": "IFTTT"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      